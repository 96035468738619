.bokser{
  margin-right: auto;
  margin-left: auto;
  margin-top: 40px;
}
.logos{
  width: 50px;
  margin: 10px auto;
}
.listwaa{
  border-inline-end: 1px solid rgba(5, 5, 5, 0.06);
  background: white !important;
}
:where(.css-dev-only-do-not-override-1kuana8).ant-menu-light.ant-menu-root.ant-menu-inline, :where(.css-dev-only-do-not-override-1kuana8).ant-menu-light > .ant-menu.ant-menu-root.ant-menu-inline, :where(.css-dev-only-do-not-override-1kuana8).ant-menu-light.ant-menu-root.ant-menu-vertical, :where(.css-dev-only-do-not-override-1kuana8).ant-menu-light > .ant-menu.ant-menu-root.ant-menu-vertical{
  border-inline-end: none !important;
}
ul.infolistwa{
  list-style: none;
  padding: 0;
  margin: 0;
}
ul.infolistwa li{
  display: inline-block;
  padding: 10px;

}
.fonter{
  font-size: 25px;
}
ul.alrtoma{
  list-style: none;
  padding: 0;
  margin: 0;
}

ul.alrtoma li{
  padding: 10px;

}
.pader{
  margin-top: 20px;
}